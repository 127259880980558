<template>
  <div>
    <van-nav-bar
      title="设置收款账户"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell-group>
      <van-field
        clearable
        label="银行"
        v-model="formdata.bank"
        readonly
      >
      </van-field>
      <van-field
        clearable
        label="开户行"
        v-model.trim="formdata.bankname"
        placeholder="请填写开户行名称"
      />
      <van-field
        clearable
        label="开户名（姓名）"
        v-model.trim="formdata.cardname"
        placeholder="请填写开户名称"
      />
      <van-field
        clearable
        label="卡号"
        v-model.trim="formdata.cardno"
        placeholder="请填写银行卡号"
      />
    </van-cell-group>
    <div class="my_tips">
      <b>用户须知：</b><br/>
      1.<font color="red">当前仅支持中国银行，其他银行无法打款</font><br/>
      2.不清楚自己的开户行，请自行查询或咨询银行客服<br/>
      3.开户名（姓名）必须和个人身份证上的姓名保持一致<br/>
    </div>

    <div style="margin: 16px">
        <van-button round block type="info" @click="doSave">保存</van-button>
      </div>
      <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data(){
    return{
      canUpdate: true,
      formdata: {
        bank: "中国银行",
        bankname: "",
        cardname: "",
        cardno: "",
      },

    }
  },
  components: {
    CopyRight,
  },

  mounted:function(){
      this.loadData();
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },

    onBankConfirm(idx) {
      this.formdata.bank = idx;
      this.showBanks = false;
    },

    loadData(){
      let token = window.sessionStorage.getItem("token");
      axios
        .get("api/member/get_info?token="+token)
        .then((res) => {
          this.formdata.bank = "中国银行";
          this.formdata.bankname = res.data.data.bankname;
          this.formdata.cardname = res.data.data.cardname;
          this.formdata.cardno = res.data.data.cardno;
          if(res.data.data.status == 1){
            this.canUpdate = false;
          }
        })
        .catch((err) => {
          console.log("profile err-->" + err);
        });
    },

    doSave(){
      if(!this.canUpdate){
        this.$toast.fail("您已经通过个人信息认证，无法再修改");
        return;
      }

      if (!this.formdata.bank) {
        this.$toast.fail("请选择银行");
        return;
      }
      if (!this.formdata.bankname) {
        this.$toast.fail("请填写开户行");
        return;
      }
      if (!this.formdata.cardname) {
        this.$toast.fail("请填写开户名");
        return;
      }
      if (!this.formdata.cardno) {
        this.$toast.fail("请填写卡号");
        return;
      }

      let postData = this.$qs.stringify({
        bank: this.formdata.bank,
        bankname: this.formdata.bankname,
        cardname: this.formdata.cardname,
        cardno: this.formdata.cardno,
        token: window.sessionStorage.getItem("token"),
      });
      axios
        .post("api/member/save_bank_info", postData)
        .then((res) => {
          if(res.data.code == 0){
            this.$toast("保存成功");
          }else{
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
